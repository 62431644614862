@mixin darkTheme($is-global: false) {
  $sel: #{&};
  @if ($is-global) {
    .isDarkTheme & {
      @content;
    }
  } @else if ($sel and $sel ==':host') {
    @at-root  :host-context(.isDarkTheme) {
      @content;
    }
  } @else if ($sel and $sel !='') {
    @at-root  :host-context(.isDarkTheme) & {
      @content;
    }
  } @else {
    :host-context(.isDarkTheme) {
      @content;
    }
  }
}

@mixin lightTheme($is-global: false) {
  $sel: #{&};
  @if ($is-global) {
    .isLightTheme & {
      @content;
    }
  } @else if ($sel and $sel ==':host') {
    @at-root  :host-context(.isLightTheme) {
      @content;
    }
  } @else if ($sel and $sel !='') {
    @at-root  :host-context(.isLightTheme) & {
      @content;
    }
  } @else {
    :host-context(.isLightTheme) {
      @content;
    }
  }
}

@mixin standardThemeTextColor {
  @include darkTheme() {
    color: $dark-theme-text-color;
  }
  @include lightTheme() {
    color: $light-theme-text-color;
  }
}


@mixin standardThemeTextColorLessIntense {
  @include darkTheme() {
    color: $dark-theme-text-color-less-intense;
  }
  @include lightTheme() {
    color: $light-theme-text-color-less-intense;
  }
}


@mixin standardThemeTextColorMostIntense {
  @include darkTheme() {
    color: $dark-theme-text-color-most-intense;
  }
  @include lightTheme() {
    color: $light-theme-text-color-most-intense;
  }
}


@mixin extraBorder($sideSuffix:'') {
  @include darkTheme {
    #{'border'+$sideSuffix}: 1px solid $dark-theme-extra-border-color;
  }
  @include lightTheme {
    #{'border'+$sideSuffix}: 1px solid $light-theme-extra-border-color;
  }
}

// e.g. cards and tasks
@mixin layerTextAndBgHigher {
  @include darkTheme() {
    color: $dark-theme-text-color-less-intense;
    background-color: $dark-theme-bg-lighter;
  }
  @include lightTheme() {
    color: $light-theme-text-color-less-intense;
  }
}


// e.g. nested cards / tabs etc
@mixin layerTextAndBgHighest {
  @include darkTheme() {
    // NOTE: needs to be more intense than light color, because we have a lighter background
    color: $dark-theme-text-color-more-intense;
    background-color: $dark-theme-bg-lightest;
  }
  @include lightTheme() {
    color: $light-theme-text-color-less-intense;
  }
}

@mixin dividerBorderColor($border-width: 0 0 1px 0, $is-global: false) {
  border-style: solid;
  border-width: $border-width;

  @include lightTheme($is-global) {
    border-color: rgba(var(--palette-foreground-divider), var(--palette-foreground-divider-alpha, 1));
  }
  @include darkTheme($is-global) {
    border-color: rgba(255, 255, 255, 0.12);
  }
}

@mixin flatBox($is-global: false) {
  @include dividerBorderColor(1px, $is-global);
}
