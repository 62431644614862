// DIALOGS
body .mat-dialog-content {
  @include scroll;
}

.big {
  height: auto;
  max-height: 100vh !important;
  // mobile viewport bug fix
  max-height: -webkit-fill-available;
  width: 100% !important;
  max-width: none !important;
  @include mq(xs) {
    margin: $s*2;
    max-height: 95vh !important;
  }

  .dialog-content,
  .mat-dialog-content {
    max-height: 100%;
  }
}

.cdk-overlay-pane {
  @include mq(xs, max) {
    max-width: 100vw !important;
  }
}

// overwrite mat typography
.mat-dialog-title.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
}

// NOTE: not really an overwrite but helpful
.dialog-header-icon {
  vertical-align: middle;
  margin-right: 8px;

  + span {
    vertical-align: middle;
    display: inline-block;
  }
}

.dialog-help-wrapper {
  position: relative;

  .help-btn {
    margin-top: 3px;
  }
}

// SNACK
// -----
.mat-snack-bar-container.mat-snack-bar-container.mat-snack-bar-container {
  padding: 0 16px !important;
  pointer-events: all;
  min-height: 48px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

body .mat-snack-bar-handset {
  pointer-events: none;
}

body .mat-form-field {
  display: block;
}

.polling-snack {
  &.mat-snack-bar-container.mat-snack-bar-container.mat-snack-bar-container {
    padding-bottom: 8px;
  }
}

// FORMS
// -----
.mat-checkbox-layout {
  white-space: normal !important;
}

// TODO remove TMP fix for https://github.com/angular/components/pull/18467
.mat-checkbox-checked .mat-checkbox-background {
  background-color: rgba(var(--palette-accent-500), 1);
}


// BUTTONS
// -------
.mat-button-base,
.mat-button {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: 100%;
}

.mat-flat-button,
.mat-stroked-button,
.mat-raised-button,
.mat-button {
  text-transform: uppercase;
}

// otherwise it's hard to see when an off-screen element gets focused via tab
.mat-flat-button.mat-primary:focus {
  &:after {
    content: '';
    position: absolute;
    border: 2px solid $c-primary-300;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    pointer-events: none;
  }
}


// CARD BG
.isDarkTheme .mat-card {
  background: $dark-theme-card-bg;
}

.mat-card {
  box-shadow: none;

  @include flatBox(true);

}


// HEADINGS
body .mat-h1 {
  margin-top: $s*1.5;
}

// BACKDROP FIX
.mat-drawer-backdrop {
  @include lightTheme(true) {
    background-color: $c-backdrop-light-theme;
  }
  @include darkTheme(true) {
    background-color: $c-backdrop-dark-theme;
  }
}
