@import '../_variables.scss';

html,
body,
app-root,
.app-frame {
  overflow: hidden;
  margin: 0;
  height: 100%;
  height: -webkit-fill-available;
  box-sizing: border-box;
  //font-family: 'Montserrat', 'Roboto', sans-serif;
  //font-family: 'Open Sans', sans-serif;
  //font-family: 'Roboto', sans-serif;
  font-family: 'Roboto', "Comic Sans MS";
}

body {
  // to fix older android web view
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  &:before {
    display: none;
    transition: 1s opacity;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
  }

  &.isEnabledBackgroundGradient {
    &:before {
      display: block;
    }
  }


  &.isLightTheme {
    color: $light-theme-text-color;

    &.isDisableBackgroundGradient {
      background: $light-theme-bg;
    }

    &:before {
      opacity: 1;
      filter: saturate(0.8);
      background: linear-gradient(180deg, $c-primary-50 0, $c-primary-100 85%, $c-primary-200 100%);
    }
  }


  &.isDarkTheme {
    color: $dark-theme-text-color;

    &.isDisableBackgroundGradient {
      background: $dark-theme-bg;
    }

    &:before {
      opacity: 1;
      filter: brightness(0.7) saturate(0.6);
      background: linear-gradient(180deg, $dark-theme-bg 0, $c-primary-900 20%, $c-primary-800 50%, $c-primary-700 100%);
      background: linear-gradient(180deg, $dark-theme-bg 0, $c-primary-900 20%, $c-primary-800 100%);
      //background: linear-gradient(180deg, $c-primary-700 0, $c-primary-800 20%, $c-primary-900 30%, $dark-theme-bg 100%);
      //background: linear-gradient(180deg, $c-primary-900 0, $c-primary-800 80%, $c-primary-700 90%, $c-primary-600 100%);
    }
  }
}

.page-wrapper {
  padding: $s $s $s*9;
  @include mq(xs) {
    padding: $s 2*$s $s*9;
  }
}

.component-wrapper {
  max-width: $component-max-width;
  margin: auto;
}

.task-list-wrapper {
  padding: 0 0 $s*9;
  // for a little bit of extra space for 800 width
  max-width: #{$component-max-width - 40};
  margin: auto;

  @include mq(xxs) {
    padding: 0 $s*1 $s*9;
  }

  @include mq(xs) {
    padding: 0 $s*1.5 $s*9;
  }

  @include mq(sm) {
    padding: 0 3*$s $s*9;
    max-width: $component-max-width;
  }

  @include mq(lg) {
    padding: 0 3*$s $s*9;
    max-width: $component-max-width +100;
  }

  improvement-banner + & {
    padding-top: 0;
  }
}


a[href] {
  color: $c-accent;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

// make images responsive per default
img {
  max-width: 100%;
  height: auto;
  display: block;
}


