@mixin mq($size, $type: min) {
  $f: 0;
  @if ($type == max) {
    $f: -1;
  }

  $width: map-get((
    'xxs' : $layout-xxs,
    'xs' : $layout-xs,
    'sm' : $layout-sm,
    'md' : $layout-md,
    'lg' : $layout-lg,
    'xl' : $layout-xl,
    'component' : $component-max-width,
  ), $size);

  @if ($width) {
    @media screen and ($type + -width: $width  + $f) {
      @content;
    }
  } @else {
    @error 'Invalid size for mq';
  }
}
