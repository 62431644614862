@import '../../variables';
/* You can add global styles to this file, and also import other style files */
@-webkit-keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes three-quarters {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Styles for old versions of IE */
button .btn-spinner {
  font-family: sans-serif;
  -webkit-animation: three-quarters 1250ms infinite linear;
  -moz-animation: three-quarters 1250ms infinite linear;
  -ms-animation: three-quarters 1250ms infinite linear;
  -o-animation: three-quarters 1250ms infinite linear;
  animation: three-quarters 1250ms infinite linear;
  border: 3px solid $c-accent;
  border-right-color: transparent;
  border-radius: 100%;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  width: 18px;
  height: 18px;
}

button .btn-spinner:not(:required) {
  margin-left: -22px;
  opacity: 0;
  transition: 0.4s margin ease-out, 0.2s opacity ease-out;
}

button.is-loading .btn-spinner {
  transition: 0.2s margin ease-in, 0.4s opacity ease-in;
  margin-left: 5px;
  opacity: 1;
}
